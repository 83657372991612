// extracted by mini-css-extract-plugin
export var hideForMobile = "ContactForm-module--hide-for-mobile--aezuo";
export var hideForTablet = "ContactForm-module--hide-for-tablet--we7qr";
export var hideForLaptop = "ContactForm-module--hide-for-laptop--rUwm-";
export var hideForDesktop = "ContactForm-module--hide-for-desktop--UKMtx";
export var hideForExtraLarge = "ContactForm-module--hide-for-extra-large--UPVqX";
export var showForMobileOnly = "ContactForm-module--show-for-mobile-only--OhwI4";
export var showForTabletOnly = "ContactForm-module--show-for-tablet-only--dkv12";
export var showForLaptopOnly = "ContactForm-module--show-for-laptop-only--YPhta";
export var showForDesktopOnly = "ContactForm-module--show-for-desktop-only--KKj4i";
export var showForExtraLargeOnly = "ContactForm-module--show-for-extra-large-only--L7gNB";
export var fullPageContainer = "ContactForm-module--full-page-container--SmHoo";
export var formContainer = "ContactForm-module--formContainer--JYEU2";
export var label = "ContactForm-module--label--Bpe--";
export var labelSet = "ContactForm-module--labelSet--BUyW7";
export var inputContainer = "ContactForm-module--inputContainer--N4LrH";
export var referralButtonContainer = "ContactForm-module--referralButtonContainer--MdC0O";
export var multiInput = "ContactForm-module--multiInput---zmDa";
export var submit = "ContactForm-module--submit--FIqYK";