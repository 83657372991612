// extracted by mini-css-extract-plugin
export var hideForMobile = "ContactFooter-module--hide-for-mobile--78Uot";
export var hideForTablet = "ContactFooter-module--hide-for-tablet--hXzc5";
export var hideForLaptop = "ContactFooter-module--hide-for-laptop--pJvqi";
export var hideForDesktop = "ContactFooter-module--hide-for-desktop--BHyCo";
export var hideForExtraLarge = "ContactFooter-module--hide-for-extra-large--mOYww";
export var showForMobileOnly = "ContactFooter-module--show-for-mobile-only--vksFG";
export var showForTabletOnly = "ContactFooter-module--show-for-tablet-only--e1hof";
export var showForLaptopOnly = "ContactFooter-module--show-for-laptop-only--ZRzTK";
export var showForDesktopOnly = "ContactFooter-module--show-for-desktop-only--AP71L";
export var showForExtraLargeOnly = "ContactFooter-module--show-for-extra-large-only--Ywj29";
export var fullPageContainer = "ContactFooter-module--full-page-container--7HvEd";
export var container = "ContactFooter-module--container--iNGT6";
export var desktop = "ContactFooter-module--desktop--9QZfT";
export var tablet = "ContactFooter-module--tablet--NwTK8";
export var mobile = "ContactFooter-module--mobile--84a3C";
export var image = "ContactFooter-module--image--hD4y+";
export var bannerText = "ContactFooter-module--bannerText--WWpIK";
export var address = "ContactFooter-module--address--Xtnqo";
export var directions = "ContactFooter-module--directions--n99Tf";
export var sideText = "ContactFooter-module--sideText--9DE-Q";