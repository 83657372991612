import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import cn from "classnames"
import * as styles from "./ContactFooter.module.scss"
import WhiteFlagIcon from "../../../assets/svgs/white-flag.svg"

const ContactFooter = () => {
  const data = useStaticQuery(graphql`
    query ContactPageFooterSectionQuery {
      desktop: contentfulAsset(
        title: { eq: "Contact Page Footer Image - Desktop" }
      ) {
        id
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
      tablet: contentfulAsset(
        title: { eq: "Contact Page Footer Image - Tablet" }
      ) {
        id
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
      mobile: contentfulAsset(
        title: { eq: "Contact Page Footer Image - Mobile" }
      ) {
        id
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
  `)

  if (!data) {
    return null
  }

  return Object.keys(data).map(screenKey => {
    const containerStyle = cn(styles.container, {
      [styles.desktop]: screenKey === "desktop",
      [styles.tablet]: screenKey === "tablet",
      [styles.mobile]: screenKey === "mobile",
    })

    const image = getImage(data[screenKey])
    return (
      <div key={data[screenKey].id} className={containerStyle}>
        <GatsbyImage image={image} loading="eager" className={styles.image} />
        <div className={styles.sideText}>OUR LOCATION</div>
        <div className={styles.bannerText}>
          <h2 className={styles.address}>
            509 Boccaccio Ave. 
            <br />
            Venice, CA 90292
          </h2>
        </div>
      </div>
    )
  })
}

export default ContactFooter
