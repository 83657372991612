import React from "react"
import loadable from "@loadable/component"

import * as styles from "./ContactHeader.module.scss"
import classNames from "classnames"
import ContactForm from "../ContactForm"
const SplitText = loadable(() => import("../../Animated/SplitText"))
const FadeUpAndIn = loadable(() => import("../../Animated/FadeUpAndIn"))

const ContactHeader = ({ handleFormState, showForm }) => {
  return (
    <div
      className={classNames(styles.container, {
        [styles.open]: showForm,
      })}
    >
      <div className={styles.headerContainer}>
        <div className={styles.header}>
          <SplitText delay={1.8}>
            Maybe it’s the work, something we said or even how we said it.
            Whatever it is - we’d love to talk about it.
          </SplitText>
        </div>
        <FadeUpAndIn delay={2.4}>
          <div className={styles.headerCta}>
            <div className={styles.ctaContainer}>
              <div className={styles.cta}>Reach Out</div>
              <a
                rel="noreferrer noopener"
                target="_blank"
                href="mailto:hello@herman-scheer.com"
                className={[styles.label, "underline-2"].join(" ")}
              >
                hello@herman-scheer.com
              </a>
            </div>
          </div>
        </FadeUpAndIn>
      </div>
      <ContactForm />
    </div>
  )
}

export default ContactHeader
