import React, { useState } from "react"
import PropTypes from "prop-types"
import * as styles from "./ContactForm.module.scss"
import { Formik } from "formik"

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const ContactForm = () => {
  const [submitSuccess, handlePostSubmit] = useState(false)


  return (
    <div className={styles.formContainer}>
      <Formik
        initialValues={{
          projectDetails: "",
          firstName: "",
          lastName: "",
          email: "",
          company: "",
        }}
        onSubmit={values => {
          fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "contact", ...values }),
          })
            .then(() => {
              handlePostSubmit(true)
              window.gtag("event", "Contact Form Submit")
            })
            .catch(error => handlePostSubmit(false))
        }}
      >
        {({ values, isSubmitting, handleChange, handleBlur, handleSubmit }) => (
          <form
            name="contact"
            method="POST"
            data-netlify="true"
            onSubmit={handleSubmit}
          >
            <div className={styles.inputContainer}>
              <label className={styles.label} htmlFor="projectDetails">
                Tell us about your business and scope of the project
              </label>
              <textarea
                type="textarea"
                name="projectDetails"
                placeholder="Start typing here..."
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.projectDetails}
              />
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.labelSet} htmlFor="firstName">
                Who can we contact to follow up?
              </label>
              <div className={styles.multiInput}>
                <input
                  type="text"
                  name="firstName"
                  placeholder="First name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                />
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                />
              </div>
              <div className={styles.multiInput}>
                <input
                  type="text"
                  name="email"
                  placeholder="Email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                <input
                  type="text"
                  name="company"
                  placeholder="Company"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.company}
                />
              </div>
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              className={styles.submit}
            >
              {submitSuccess ? (
                <span>Thank you for your submission!</span>
              ) : (
                <span>{isSubmitting ? "Sending..." : "Submit"}</span>
              )}
            </button>
          </form>
        )}
      </Formik>
    </div>
  )
}

ContactForm.propTypes = {
  showForm: PropTypes.bool.isRequired,
}

export default ContactForm
