import React, { useState } from "react"

import ContactHeader from '../components/Contact/ContactHeader'
import ContactFooter from '../components/Contact/ContactFooter'
import SEO from "../components/seo"
import FadeIn from "../components/Animated/FadeIn"

const ContactPage = () => {
  const [showForm, handleFormState] = useState(false)

  return (
    <FadeIn>
      <SEO
        title="Contact | Get In Touch"
        description="We are actively discussing new business opportunities. "
      />
      <div className="contact-container">
        <ContactHeader handleFormState={handleFormState} showForm={showForm} />
        <ContactFooter />
      </div>
    </FadeIn>
  )
}

export default ContactPage